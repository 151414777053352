
import { Redirect } from "react-router-dom";
import React from "react";

export function getSession() {
  let session;
  try {
    session = JSON.parse(sessionStorage.getItem('session'));
  } catch (e) {
    throw (e);
  }
  return session;
}

export function getUserData() {
  const session = getSession();
  return session ? session.user : undefined;
}

export function selfIdVerification(id) {
  return getUserData().id === id ? true : false;
}

export function getUserRgn() {
  const session = getSession();
  return session ? session.userRgn : undefined;
}

export function getUserContextPathFirst() {
  const session = getSession();
  return session && !!session.userRgn.length ? session.userRgn[0].contextPath : undefined;
}

export function getUserRgnFirst() {
  const session = getSession();
  return !!session.userRgn ? session.userRgn[0] : false;
}

export function setSession(session: any): void {
  sessionStorage.setItem('session', JSON.stringify(session));
}

export function getAccessToken() {
  const session = getSession();
  return session ? session.tokens.access.token : undefined;
}

export function logout() {
  sessionStorage.clear();
}

export function isAuthenticated(): bool {
  const session = getSession();
  let isSessionValid = session;
  if (session) {
    const todayDate = new Date();
    const expirationDate = new Date(session.tokens.access.expires);
    isSessionValid = todayDate < expirationDate;
  }

  return isSessionValid;
}

export const RequireAuth = ({ children }) => {
  if (!isAuthenticated()) {
    console.info("You're not authorized, please login");
    return <Redirect to={'/login'} />;
  }

  return children;
};

export function isGlobalUser(role = getUserData().role, type = false) {
  let resp = false;

  switch (role) {
    case 'ai_admin': resp = true; break
    case 'ai_registrator': resp = true; break
    case 'ai_manager': resp = true; break
    default: break;

  }

  if (type) {
    switch (role) {
      case 'country_manager': resp = true; break
      case 'salesman_country': resp = true; break
      case 'territory_manager': resp = true; break
      default: break;
    }
  }

  return resp;
}

export const getRoleField = (role) => {
  const roleMap = {
    ai_admin: 'AI Admin',
    ai_registrator: 'AI Registrator',
    ai_manager: 'AI Manager',
    country_manager: 'Country Manager',
    territory_manager: 'Territory Manager',
    manager: 'Manager',
    quality_manager: 'Quality Manager',
    facility_manager: 'Facility Manager',
    operator: 'Operator',
    client_user: 'Client User',
    salesman_country: 'Salesman Country'
  }

  return roleMap[role] ? roleMap[role] : role;
}

export const filterDataByRole = (data, typeData) => {
  const userRgns = getUserRgn();
  const User = getUserData();
  const idsRgnUser = userRgns.map((obj) => obj.contextPath);
  let response = null;
  let listRgns = [];

  data.dataOrigin.map(obj => {
    if (idsRgnUser.find(idrgn => obj.eid === idrgn || obj.parentId === idrgn || obj.meta.gparentId === idrgn)) {
      obj.eid && listRgns.push(obj.eid);
      obj.parentId && listRgns.push(obj.parentId);
    }

    if (User.role !== 'site_user' && User.role !== 'client_user') {
      if (listRgns.find(idrgn => obj.eid === idrgn || obj.parentId === idrgn || obj.meta.gparentId === idrgn)) {
        obj.eid && listRgns.push(obj.eid);
        obj.parentId && listRgns.push(obj.parentId);
        obj.meta.gparentId && listRgns.push(obj.meta.gparentId);
      }
    }

    return obj;
  });

  listRgns = [...new Set(listRgns)];

  response = data.data.filter(obj => {
    const validate = filterDataByTypeData(obj, typeData, listRgns, User.role)
    return (validate) ? obj : null;
  })

  return response;
}

export const filterDataByTypeData = (objComparation, typeData, idsRgnUser, role) => {
  let validate = false;
  let propertyComparison;
  let v;

  switch (typeData) {
    case 'client':
      propertyComparison = 'eid';
      break;
    case 'site':
      propertyComparison = role === 'site_user' ? 'eid' : 'parentId';
      break;
    case 'wtd':
      propertyComparison = 'rgnId';
      break;
    case 'router':
      propertyComparison = 'rgnId';
      break;
    default:
      break;
  }

  if (objComparation) {
    const RgnId = objComparation[propertyComparison]
    v = idsRgnUser.filter(ob => ob === RgnId)
    validate = (v.length > 0) ? true : false;
  }

  return validate;
}

const PermissionForRole = {
  salesman_country: {
    AddCountry: [],
    AddTerritory: [],
    AddClient: [],
    AddSite: [],
    AddWTD: [],
    AddPLC: [],
    AddRouter: [],
    ClientList: [],
    SiteList: [],
    WTDList: [],
    PLCList: [],
    RoutersList: [],
  }
}

export const checkOptions = (page, operation) => {
  let check = true;

  if (PermissionForRole[getUserData().role]) {
    check = PermissionForRole[getUserData().role][page].filter(i => i === operation).length > 0 ? true : false;
  }

  return check;
}

export const roleMap = {
  ai_admin: {
    name: 'AI Admin',
    badge: 'danger'
  },
  ai_registrator: {
    name: 'AI Registrator',
    badge: 'danger'
  },
  ai_manager: {
    name: 'AI Manager',
    badge: 'danger'
  },
  country_manager: {
    name: 'Country Manager',
    badge: 'info'
  },
  salesman_country: {
    name: 'Salesman Country',
    badge: 'info'
  },
  territory_manager: {
    name: 'Territory Manager',
    badge: 'info'
  },



  manager: {
    name: 'Manager',
    badge: 'info'
  },
  quality_manager: {
    name: 'Quality Manager',
    badge: 'info'
  },
  facility_manager: {
    name: 'Facility Manager',
    badge: 'info'
  },
  operator: {
    name: 'Operator',
    badge: 'info'
  },
  client_user: {
    name: 'Client User',
    badge: 'info'
  },
  site_user: {
    name: 'Site User',
    badge: 'info'
  }
}

export function isRestrictedRole(role) {
  const restrictedRoles = [
    "manager",
    "quality_manager",
    "facility_manager",
    "operator",
    "client_user",
    "site_user"
  ];
  return restrictedRoles.includes(role);
}
